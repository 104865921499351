<template>
    <div class="pc-container">
        <div v-html="content" class="content"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                content: ''
            }
        },
        created() {
            let slug = this.$route.query.slug
            if(slug) {
                this.getSetting(slug)
            }
        },
        computed: {
            curSlug() {
                return this.$route.query.slug
            }
        },
        watch: {
            curSlug(val) {
                // console.log(val)
                if(val) {
                    this.getSetting(val)
                }
            }
        },
        methods: {
            getSetting(slug) {
                this.$api.setting_lang({
                    slug
                },300000).then(res => {
                    if(res.data) {
                        this.content = res.data
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;
        box-sizing: border-box;
        padding: 40px $box-padding;
        background-color: #f6f6f6;
        
        .content {
            width: 100%;
            word-wrap: break-word;
        }
    }
</style>